import habitat from 'preact-habitat'
import Widget from './components/chatbot'
import axiosConfig from './api/config/axios.config'

const widgetContainerId = 'formaloo-chatbot-container'

axiosConfig()

;(function () {
  const scriptElement = document.getElementById('formaloo-chatbot-script')
  if (!scriptElement) {
    console.error('Script element not found!')
    return
  }

  try {
    const divElement = document.createElement('div')
    divElement.id = widgetContainerId

    // Copy style and dir attributes separately
    const style = scriptElement.getAttribute('style')
    if (style) {
      divElement.setAttribute('style', style)
    }

    const dir = scriptElement.getAttribute('dir')
    if (dir) {
      divElement.setAttribute('dir', dir)
    }

    Array.from(scriptElement.attributes).forEach(({ name, value }) => {
      if (name.startsWith('data-')) {
        divElement.setAttribute(name, value)
      }
    })

    document.body.appendChild(divElement)

    const _habitat = habitat(Widget)
    _habitat.render({ selector: `#${widgetContainerId}`, clean: true })
  } catch (error) {
    console.error('Error occurred while creating the container:', error)
    return
  }
})()
