/**
 * Convert a JSON color to RGBA format with optional custom opacity
 * or return the default color in case of an exception
 *
 * @param {string} jsonColor - The JSON color to be converted
 * @param {number} customOpacity - Optional custom opacity value
 * @param {string} defaultColor - The default color to return in case of an exception
 * @return {string} The RGBA color string or the default color in case of an exception
 */
export function jsonColorConverter(
  jsonColor,
  customOpacity,
  defaultColor = 'rgba(0, 0, 0, 1)',
) {
  try {
    const colorObject = JSON.parse(jsonColor)
    const opacity = customOpacity ?? colorObject.a
    return `rgba(${colorObject.r}, ${colorObject.g}, ${colorObject.b}, ${opacity})`
  } catch (error) {
    return defaultColor ?? defaultColor
  }
}

export function convertColorToRGBA(colorJSON, percent = 100) {
  if (colorJSON) {
    let parsedColor = JSON.parse(colorJSON)
    if (isColorJSON(parsedColor)) {
      const { r, g, b, a } = parsedColor
      return `rgba(${r}, ${g}, ${b}, ${a ? a * (percent / 100) : percent})`
    } else {
      return colorJSON
    }
  }
}
export function isColorJSON(input) {
  return (
    typeof input === 'object' &&
    input !== null &&
    'r' in input &&
    'g' in input &&
    'b' in input &&
    'a' in input
  )
}

export function updateAlpha(rgba, newAlpha) {
  // Check if the input is null or undefined
  if (!rgba || typeof rgba !== 'string') {
    return
  }

  // Check if the new alpha is valid
  if (newAlpha === null || newAlpha === undefined || isNaN(newAlpha)) {
    return
  }

  // Ensure newAlpha is within the valid range
  newAlpha = Math.max(0, Math.min(1, newAlpha))

  // Extract the current RGBA values
  const rgbaMatch = rgba.match(
    /rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d\.]+)?\)/,
  )

  if (!rgbaMatch) {
    return
  }

  // Destructure the match array into variables
  const [_, r, g, b] = rgbaMatch

  // Return the new RGBA string with the updated alpha
  return `rgba(${r}, ${g}, ${b}, ${newAlpha})`
}

export function getBrightnessColor(brightness) {
  // Ensure brightness is within the valid range [-100, 100]
  const normalizedBrightness = Math.max(Math.min(brightness, 100), -100)

  // Calculate the RGB values based on normalized brightness
  const value = ((normalizedBrightness + 100) / 200) * 255

  // Map the brightness value to the alpha (transparency) in the RGBA color model
  const alpha = Math.abs(normalizedBrightness / 100)

  return `rgba(${value}, ${value}, ${value}, ${alpha})`
}
export const hexToRGBA = (hex, alpha) => {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '')

  // Parse r, g, b values from the hex color
  let r = parseInt(hex.substring(0, 2), 16)
  let g = parseInt(hex.substring(2, 4), 16)
  let b = parseInt(hex.substring(4, 6), 16)

  // Return the RGBA color string
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}
