export default function general(state = {}, action) {
  switch (action.type) {
    case 'END_LOADING':
      return { ...state, isLoadingFields: false }

    case 'HAD_ERROR_LOADING_FORM':
      return { ...state, errorLoadingFields: action.error || true }

    case 'FORM_SUCCESSFULLY_SUBMITTED': {
      return {
        ...state,
        formHasSubmittedSuccessfully: true,
        successRedirectUrl: action.successRedirectUrl,
        //submitCode used for answer sheet
        submitCode: action.submitCode,
      }
    }
    case 'FORM_RESET': {
      return {}
    }
    default:
      return state
  }
}

//  general: {
//     isLoadingFields: true,
//     errorLoadingFields: false,
//   }
