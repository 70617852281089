import { config, createFormData } from './utils'
import API from 'settings/api.settings'
import { httpClient } from './utils/http'

export const form = () => ({
  getOne: (formSlug, params = {}) =>
    httpClient.get(
      `${API.baseUrl}/v4/form-displays/slug/${formSlug}/`,
      config({ params }),
    ),

  submit: (formData, formSlug, params = {}) =>
    httpClient.post(
      `${API.baseUrl}/v3.0/form-displays/slug/${formSlug}/submit/`,
      formData,
      config({ params }),
    ),

  updateDraft: (formData, submitCode, params = {}) =>
    httpClient.patch(
      `${API.baseUrl}/v3.2/draft-rows/${submitCode}/`,
      formData,
      config({ params }),
    ),

  getFieldChoices: (fieldSlug, params) =>
    httpClient.get(
      `${API.baseUrl}/v3.0/fields/${fieldSlug}/choices/`,
      config({ params }),
    ),
})
