export default function fieldDefaultValue(fieldType) {
  switch (fieldType) {
    // checkbox is the one that should always have a value (Hasan says so!)
    case 'checkbox':
      return {}

    case 'multiple_select':
      return []

    default:
      return undefined
  }
}
