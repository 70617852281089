import { actionTypes } from '../actions/actionTypes'
import { isFieldPageBreak, logicActionTypes } from 'utils/types'
import compose from 'ramda/src/compose'
import sort from 'ramda/src/sort'
import { objToArr, sortFieldsByPosition } from 'utils/helpers'

import { fieldTypes, fieldSubTypes, formTypes } from 'utils/types'

const INITIAL_STATE = {}

/**
 * @summary filters out redirect after submit actions from Logic
 *
 * @param {Array} logic
 */
const removeRedirectAfterSubmits = (logic = []) => {
  if (!logic) return []

  return logic
    .map((fieldLogic) => ({
      ...fieldLogic,
      actions: fieldLogic.actions.filter(
        (item) => item.action !== logicActionTypes.redirect
      ),
    }))
    .filter((fieldLogic) => fieldLogic.actions.length > 0)
}

export default function mainForm(state = INITIAL_STATE, action) {


  switch (action.type) {
    case actionTypes.LOADED_FORM:
      return {
        ...action.formData,
        // ...paginateForm(action.formData),
        // ...extractFormMetaData(action.formData),
        // logic: removeRedirectAfterSubmits(action.formData.logic),
        // formCurrentPage: 0,
      }

    case actionTypes.SET_FORM_PAGE:
      return {
        ...state,
        formCurrentPage: action.page,
      }

    default:
      return state
  }
}

// form: {
//       buttonColor: null,
//       buttonText: null,
//       description: null,
//       emailFields: [], // will be used in future! 💩
//       errorMessage: null,
//       fields: {},
//       logo: null,
//       logoPosition: null,
//       maxSubmit_count: null,
//       needsLogin: false,
//       owner: {},
//       primaryEmail: null,
//       sendEmails: true,
//       slug: '',
//       submitCount: 0,
//       submitEndTime: null,
//       submitStartTime: null,
//       successMessage: null,
//       textColor: null,
//       themeColor: null,
//       title: ''
//     },

const getClientVisibleWritableFields = (fields) =>
  fields.filter(
    (f) =>
      ![fieldTypes.meta, fieldTypes.variable, fieldTypes.oembed].includes(
        f.type
      )
  )

const formHasPageBreak = (fields) =>
  fields.filter(
    (f) =>
      f.type === fieldTypes.meta &&
      f.sub_type === fieldSubTypes[f.type].page_break
  ).length > 0

const formIsMultiPage = ({ form_type: type }) => type === formTypes.multiPage

const formIsNps = ({ form_type: type }) => type === formTypes.nps

export const extractFormMetaData = (form) => {
  const isFormMultiPage = formIsMultiPage(form)

  return {
    isFormNps: formIsNps(form),
    hasPageBreak: formHasPageBreak(form.fields_list),
    inputFieldsCount: getClientVisibleWritableFields(form.fields_list)?.length,
    clientVisibleWritableFields: getClientVisibleWritableFields(
      form.fields_list
    ),
    isFormMultiPage,
  }
}

export const processFields = compose(sort(sortFieldsByPosition), objToArr)

const paginateForm = ({ fields, form_type }) => {
  let paginatedFields = [],
    fieldPageMap = {},
    page = 0,
    fullWidth = true
  const isMultiStep = form_type === formTypes.multiPage
  const sortedFieldsArray = processFields(fields)

  for (const field of sortedFieldsArray) {
    if (isFieldPageBreak(field)) {
      page = page + 1
      continue
    }
    paginatedFields[page] = paginatedFields[page]
      ? [...paginatedFields[page], { ...field, fullWidth, page }]
      : [{ ...field, fullWidth, page }]

    fieldPageMap[field.slug] = isMultiStep ? field.position : page + 1
  }

  return page > 0
    ? {
      paginated_fields: paginatedFields,
      fieldPageMap,
    }
    : {
      paginated_fields: paginatedFields[0],
      fieldPageMap,
    }
}
