import isNil from 'ramda/src/isNil'
import isEmpty from 'ramda/src/isEmpty'
import { fieldTypeIs, fieldSubTypes, fieldTypes } from './types'
import settings from '../settings'

const { LOCALE_IN_USE } = settings

export const resOK = status => status >= 200 && status < 400

export function The(promise) {

  return promise
    .then(({ data: { status, errors, data } }) => {

      if (resOK(status)) {
        return [null, data]
      } else {
        return [errors, null]
      }
    })
    .catch(e => {
      console.log('catch: ', e)
      return ['networkError', null]
    })
}


export function thousandsSeparator(rowNum) {
  return rowNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
export const toPersianDigit = (a) => {
  if (a !== null && a !== undefined) {
    if (typeof a === 'number') {
      a = a.toString()
    }

    return a.replace(/\d+/g, function (digit) {
      var enDigitArr = [],
        peDigitArr = []

      for (let i = 0; i < digit.length; i++) {
        enDigitArr.push(digit.charCodeAt(i))
      }

      for (let j = 0; j < enDigitArr.length; j++) {
        peDigitArr.push(
          String.fromCharCode(enDigitArr[j] + (!!a && a === true ? 1584 : 1728))
        )
      }

      return peDigitArr.join('')
    })
  } else {
    return ''
  }
}

export const replaceObjKey = (replacements) => (obj) =>
  Object.keys(obj)
    .map((key) => {
      const newKey = replacements[key] || key
      return { [newKey]: obj[key] }
    })
    .reduce((acc, obj) => ({ ...acc, ...obj }), {})

export const objToArr = (object) => {
  const result = []

  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      result.push(object[key])
    }
  }

  return result
}

// last one is dot for floating point numbers
var persian = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '.']
var english = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']

const engToPerMapper = (char) => {
  if (english.indexOf(char) !== -1 && persian.indexOf(char) !== -1) {
    // return null if it is neither persian nor english number
    // because it a alphabetic char
    return null
  } else if (persian.indexOf(char) !== -1) {
    return char
  } else if (english.indexOf(char) !== -1) {
    return persian[english.indexOf(char)]
  }
}

const perToEngMapper = (char, acceptDecimal) => {
  if (
    !acceptDecimal &&
    english.indexOf(char) !== -1 &&
    persian.indexOf(char) !== -1
  ) {
    // return null if it is neither persian nor english number
    // because it a alphabetic char
    return null
  } else if (persian.indexOf(char) !== -1) {
    return english[persian.indexOf(char)]
  } else if (english.indexOf(char) !== -1) {
    return char
  }
}

const numericCharFilter = (char, acceptDecimal) => {
  if (
    !acceptDecimal &&
    english.indexOf(char) !== -1 &&
    persian.indexOf(char) !== -1
  ) {
    return false
  } else if (persian.indexOf(char) !== -1) {
    return true
  } else if (english.indexOf(char) !== -1) {
    return true
  }
}

export const onlyEnglishNumbers = (value) => {
  if (!value) return ''

  return value
    .split()
    .filter((char) => !english.includes(char))
    .join()
}

export const ensurePersianNumber = (text) => {
  if (typeof text === 'string') {
    return text.split('').map(engToPerMapper).filter(Boolean).join('')
  } else {
    return ''
  }
}

export const ensureEnglishNumber = (text, isDecimal) => {
  if (typeof text === 'string') {
    return text
      .split('')
      .map((char) => perToEngMapper(char, isDecimal))
      .filter(Boolean)
      .join('')
  } else {
    return ''
  }
}

export const filterNonNumericChars = (text, isDecimal) => {
  if (typeof text === 'string') {
    return text
      .split('')
      .filter((char) => numericCharFilter(char, isDecimal))
      .join('')
  } else {
    return ''
  }
}

export const reverseString = (string) => string.split('').reverse().join('')

export function replaceLastInstance(string, charToReplace, Replacement) {
  return reverseString(
    reverseString(string).replace(charToReplace, Replacement)
  )
}

// this is a light replacement for moment-jalaali
export const convertToJalaali = (dateString) =>
  new Intl.DateTimeFormat('fa-IR').format(new Date(dateString))

export const createFormData = (formObject, customBlackList = []) => {
  let formData = new FormData()
  const blackList = [null, undefined, ...customBlackList]

  for (const field in formObject) {
    const fieldValue = formObject[field]

    if (!blackList.includes(fieldValue)) {
      if (typeof fieldValue === 'object') {
        if (fieldValue instanceof FileList) {
          // it's a file
          if (fieldValue.length === 1) {
            formData.append(field, fieldValue[0])
          } else {
            for (let i = 0; i < fieldValue.length; i++) {
              formData.append(`${field}[]`, fieldValue[i])
            }
          }
        } else if (Array.isArray(fieldValue)) {
          //it's a array don't convert to json (it confuses the server)
          formData.append(field, fieldValue)
          // if it is a single object as file (not in file list)
        } else if (fieldValue.name && fieldValue.type && fieldValue.size) {
          formData.append(field, fieldValue)
        } else {
          // it's an object
          formData.append(field, JSON.stringify(fieldValue))
        }
      } else {
        // it's not an object
        formData.append(field, fieldValue)
      }
    }
  }
  return formData
}

export const stripFirstChar = (string) => string.substring(1)

export const renameObjectKey =
  (transformations) =>
    (object = {}) => {
      // sample transformations { options: 'choice_items', label: 'title' }

      /*
      NOTE:
        here the object should be mutated and not be copied,
        because if a new object is returned each time, fields will
        receive it and will re-update and so on
    */
      const transformedObject = { ...object }
      for (const key in transformations) {
        if (transformations.hasOwnProperty(key)) {
          const propertyToLift = transformations[key]

          if (transformedObject[propertyToLift]) {
            transformedObject[key] = transformedObject[propertyToLift]

            delete transformedObject[propertyToLift]
          }
        }
      }

      return transformedObject
    }

export const sortFieldsByPosition = (field1, field2) =>
  field1.position > field2.position ? 1 : -1

export const cloneObjectIfMappingExists = (mapping) => (src, target) => {
  /*
    clones a property from src to target using the
    mapping Object if src[property] != falsy
  */

  for (const key in mapping) {
    if (mapping.hasOwnProperty(key)) {
      const propertyToClone = mapping[key]

      if (src[propertyToClone]) {
        target[key] = src[propertyToClone]
      }
    }
  }

  return target
}

const isIn = (list, item) => list.indexOf(item) !== -1

export const omitKeys = (blackList) => (object) => {
  /*
    NOTE: this function mutates it's input
  */
  if (!blackList || !Array.isArray(blackList))
    throw new TypeError('omitKeys: blackList must be an array')

  if (object)
    for (const key in object)
      if (object.hasOwnProperty(key))
        if (isIn(blackList, key)) delete object[key]

  return object
}

export const getKeyByValue = (object, value) =>
  Object.keys(object).find((key) => object[key] === value)

export const isFalsyOrEmpty = (v) => isNil(v) || isEmpty(v)

export const shouldShowHint = (field) =>
  field.hotkeysEnabled &&
  fieldTypeIs(field, fieldSubTypes[fieldTypes.meta].section)

export function isJSON(str) {
  if (typeof str !== 'object' && Object.keys(JSON.parse(str)).length)
    return true
  return false
}

export const toLocaleNumber = (digit) => {
  const shouldConvertToPersian = ['fa'].includes(LOCALE_IN_USE)
  return shouldConvertToPersian ? toPersianDigit(digit) : digit
}

export const isNumber = (n) => {
  return (
    typeof n === 'number' ||
    (!isNaN(parseFloat(n)) && isFinite(n) && parseFloat(n) === Number(n))
  )
}


export const parseJSONSafely = (jsonString, defaultReturn = {}) => {
  try {
    return JSON.parse(jsonString)
  } catch (error) {
    console.error('Error parsing JSON:', error)
    return defaultReturn // or any default value you prefer
  }
}
