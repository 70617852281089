import { createStore, compose, applyMiddleware } from 'redux'
import rootReducer from '../reducers/index'
import { defaultState } from './defaultState'
import { actionTypes } from '../actions/actionTypes'
import settings from '../settings'

const { LOCALE_IN_USE } = settings
const middleWares = []
let composer = compose

//
// ─── ENABLE REDUX DEV TOOLS IF NOT IN DEV MODE ──────────────────────────────────
//

if (process.env.NODE_ENV === 'development') {
  composer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  import('redux-logger').then(({ createLogger }) => {
    middleWares.push(
      createLogger({
        collapsed: true,
      })
    )
  })
}

// middleWares.push(LogRocket.reduxMiddleware())

//
// ─── CREATE STORE AND APPLY MIDDLE-WARES ────────────────────────────────────────
//

const store = createStore(
  rootReducer,
  defaultState,
  composer(applyMiddleware(...middleWares))
)

//
// ─── SET LOCALE IN USE FROM THE SETTINGS ──────────────────────────────────────────
//

store.dispatch({
  type: actionTypes.SET_LOCALE_IN_USE,
  payload: { localeInUse: LOCALE_IN_USE },
})


// ────────────────────────────────────────────────────────────────────────────────

export default store
