import { actionTypes } from '../actions/actionTypes'

const INITIAL_STATE = {}

/* 
  history = {
    Every variable has a change log that shows which fields and constants have changed the value
    variable_field_1_slug : [
        {
        logicField: full data of the field that ows the logic rule,
        operandIdentifier: field_identifier,
        value: value of the field or constant ,
        action,
        operandType: field or constant
        variableType: int, decimal, string
        whenArgs: args of the condition
      },
       {
        logicField: full data of the field that ows the logic rule,
        operandIdentifier: field_identifier,
        value: value of the field or constant ,
        action,
        operandType: field or constant
        variableType: int, decimal, string
        whenArgs: args of the condition

      },
      .
      .
      .
    ],
    variable_field_2_slug : [...]
  }
*/
export default function fieldsValueHistory(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.UPDATE_HISTORY:
      return {
        ...state,
        ...action.data,
      }
    case actionTypes.UPDATE_SINGLE_FIELD_HISTORY:
      return {
        ...state,
        [action.field]: [...(state[action.field] || []), action.newChange],
      }
    case actionTypes.RESET_SINGLE_FIELD_HISTORY:
      return {
        ...state,
        [action.field]: action.data || [],
      }
    default:
      return state
  }
}
