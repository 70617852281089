const DEFAULT_BASE = 'https://ai-api.formaloo.me'

import { httpClient } from './utils/http'
import API from 'settings/api.settings'

export const ai = () => ({
  getResult: slug => {
    return httpClient.get(
      `${API.aiUrl ?? DEFAULT_BASE}/v1/custom-prompt-results/${slug}/`,
    )
  },
})

// export type PromptResult = {
//     slug: string;
//     result: string;
//     errors: string | null;
//     status: 'completed' | 'in_progress';
//     created_at: string;
//   };
