const numbers = {
  0: { code: 'Digit0', keys: ['0', '۰'], keyCode: 0 },
  1: { code: 'Digit1', keys: ['1', '۱'], keyCode: 1 },
  2: { code: 'Digit2', keys: ['2', '۲'], keyCode: 2 },
  3: { code: 'Digit3', keys: ['3', '۳'], keyCode: 3 },
  4: { code: 'Digit4', keys: ['4', '۴'], keyCode: 4 },
  5: { code: 'Digit5', keys: ['5', '۵'], keyCode: 5 },
  6: { code: 'Digit6', keys: ['6', '۶'], keyCode: 6 },
  7: { code: 'Digit7', keys: ['7', '۷'], keyCode: 7 },
  8: { code: 'Digit8', keys: ['8', '۸'], keyCode: 8 },
  9: { code: 'Digit9', keys: ['9', '۹'], keyCode: 9 },
}

const alphabet = {
  a: { code: 'KeyA', keys: ['a', 'A', 'ش'], keyCode: 65 },
  b: { code: 'KeyB', keys: ['b', 'B', 'ذ'], keyCode: 66 },
  c: { code: 'KeyC', keys: ['c', 'C', 'ز'], keyCode: 67 },
  d: { code: 'KeyD', keys: ['d', 'D', 'ی'], keyCode: 68 },
  e: { code: 'KeyE', keys: ['e', 'E', 'ث'], keyCode: 69 },
  f: { code: 'KeyF', keys: ['f', 'F', 'ب'], keyCode: 70 },
  g: { code: 'KeyG', keys: ['g', 'G', 'ل'], keyCode: 71 },
  h: { code: 'KeyH', keys: ['h', 'H', 'ا'], keyCode: 72 },
  i: { code: 'KeyI', keys: ['i', 'I', 'ه'], keyCode: 73 },
  j: { code: 'KeyJ', keys: ['j', 'J', 'ت'], keyCode: 74 },
  k: { code: 'KeyK', keys: ['k', 'K', 'ن'], keyCode: 75 },
  l: { code: 'KeyL', keys: ['l', 'L', 'م'], keyCode: 76 },
  m: { code: 'KeyM', keys: ['m', 'M', 'پ'], keyCode: 77 },
  n: { code: 'KeyN', keys: ['n', 'N', 'د'], keyCode: 78 },
  o: { code: 'KeyO', keys: ['o', 'O', 'خ'], keyCode: 79 },
  p: { code: 'KeyP', keys: ['p', 'P', 'ح'], keyCode: 80 },
  q: { code: 'KeyQ', keys: ['q', 'Q', 'ض'], keyCode: 81 },
  r: { code: 'KeyR', keys: ['r', 'R', 'ق'], keyCode: 82 },
  s: { code: 'KeyS', keys: ['s', 'S', 'س'], keyCode: 83 },
  t: { code: 'KeyT', keys: ['t', 'T', 'ف'], keyCode: 84 },
  u: { code: 'KeyU', keys: ['u', 'U', 'ع'], keyCode: 85 },
  v: { code: 'KeyV', keys: ['v', 'V', 'ر'], keyCode: 86 },
  w: { code: 'KeyW', keys: ['w', 'W', 'ص'], keyCode: 87 },
  x: { code: 'KeyX', keys: ['x', 'X', 'ط'], keyCode: 88 },
  y: { code: 'KeyY', keys: ['y', 'Y', 'غ'], keyCode: 89 },
  z: { code: 'KeyZ', keys: ['z', 'Z', 'ظ'], keyCode: 90 },
}

export const KEYBOARD_MAP = {
  enter: { code: 'Enter', keys: ['Enter'], keyCode: 13 },
  down: { code: 'ArrowDown', keys: ['ArrowDown'], keyCode: 40 },
  up: { code: 'ArrowUp', keys: ['ArrowUp'], keyCode: 38 },
  ...numbers,
  ...alphabet,
}

export const isAlpha = selectedKey =>
  selectedKey.keyCode >= KEYBOARD_MAP.a.keyCode &&
  selectedKey.keyCode <= KEYBOARD_MAP.z.keyCode

export const isNumeric = selectedKey =>
  selectedKey.keyCode >= KEYBOARD_MAP[0].keyCode &&
  selectedKey.keyCode <= KEYBOARD_MAP[9].keyCode

export const isNumericSequence = (selectedKeys = []) =>
  selectedKeys
    .map(k => k.keyCode)
    .reduce(
      (prev, curr) =>
        prev &&
        curr >= KEYBOARD_MAP[0].keyCode &&
        curr <= KEYBOARD_MAP[9].keyCode,
      true,
    )

export const getAlphaKeyByIndexFromA = index => {
  const alphaKeyCode = alphabet.a.keyCode + index
  return String.fromCharCode(alphaKeyCode)
}

export const getKeyboardEventCode = event => {
  const keyboardMap = Object.values(KEYBOARD_MAP)

  if (event.code) {
    const currentKey = keyboardMap.filter(k => k.code === event.code)
    if (currentKey.length === 1) return currentKey[0]
  }

  if (event.key) {
    const currentKey = keyboardMap.filter(k => k.keys.includes(event.key))
    if (currentKey.length === 1) return currentKey[0]
  }

  return { code: undefined, keys: [], keyCode: undefined }
}

export const handleKeyDown = ({ goNext, goBack }) => event => {
  const currentKeyCode = getKeyboardEventCode(event)

  switch (currentKeyCode.code) {
    case KEYBOARD_MAP.enter.code:
      if (!event.shiftKey) {
        event.preventDefault()
        goNext(event)
      }
      break

    case KEYBOARD_MAP.down.code:
      goNext(event)
      break

    case KEYBOARD_MAP.up.code:
      goBack(event)
      break

    default:
      break
  }
}
