import { actionTypes } from './actionTypes'

export const setLocaleInUse = (locale) => ({
  type: actionTypes.SET_LOCALE_IN_USE,
  payload: {
    localeInUse: locale,
  },
})

export const formSuccessfullySubmitted = (submitData) => ({
  // submitData contains => { successRedirectUrl, submitCode }
  type: actionTypes.FORM_SUCCESSFULLY_SUBMITTED,
  ...submitData,
})

export const setFormCurrentPage = (page) => ({
  type: actionTypes.SET_FORM_PAGE,
  page,
})

export const setGeneralDataToSubmit = (data) => ({
  type: actionTypes.SET_GENERAL_SUBMIT_DATA,
  data,
})

export const updateFieldsHistory = (data) => ({
  type: actionTypes.UPDATE_HISTORY,
  data,
})

export const updateSingleFieldsHistory = (field, newChange) => ({
  type: actionTypes.UPDATE_SINGLE_FIELD_HISTORY,
  field,
  newChange,
})

export const resetSingleFieldsHistory = (field, data) => ({
  type: actionTypes.RESET_SINGLE_FIELD_HISTORY,
  field,
  data,
})
