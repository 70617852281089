import equals from 'ramda/src/equals'
import { fieldTypes } from '../../utils/types'

export const formatValue = (value, type) => {
  if (['int', 'decimal'].includes(type)) return Number(value)
  return `${value}`
}
export const setHistoryLogValue = (value, operator) => {
  if (!value) {
    switch (operator) {
      case 'multiply':
      case 'divide':
        return 1

      default:
        return 0
    }
  }
  return value
}

export const convertMathOperators = operator => {
  switch (operator) {
    case 'multiply':
      return '*'
    case 'divide':
      return '/'
    case 'add':
      return '+'
    case 'subtract':
      return '-'
    default:
      return ''
  }
}

export const convertType = (value, type) => {
  switch (type) {
    case 'int':
      return parseInt(value) || 0
    case 'string':
      return value || ''
    case 'decimal':
      return Number(value) || 0.0
    default:
      return value
  }
}

const checkConditionArgs = (historyLog, newLog, logicFieldType) => {
  if (logicFieldType === fieldTypes.multiple_select)
    return equals(historyLog, newLog) // check history of each option
  return true
}

export const findExitingHistoryLog = (allLogs, theLog) => {
  switch (theLog.operandType) {
    case 'field':
      return allLogs.findIndex(
        log =>
          log.logicField?.slug === theLog.field?.slug &&
          log.operandIdentifier === theLog.secondOperand.identifier &&
          log.action === theLog.action &&
          checkConditionArgs(log.whenArgs, theLog.whenArgs, theLog.field?.type), // check the exact args of condition (this will affect the multi-choice logic)
      )
    default:
      return allLogs.findIndex(
        log =>
          log.logicField?.slug === theLog.field?.slug &&
          log.action === theLog.action &&
          checkConditionArgs(log.whenArgs, theLog.whenArgs, theLog.field?.type), // check the exact args of condition (this will affect the multi-choice logic)
      )
  }
}

export const executeExpression = expression => {
  try {
    // eslint-disable-next-line no-new-func
    const result = Function(`"use strict";return (${expression})`)()
    return isNaN(result) ? 0 : result // Return 0 if result is NaN
  } catch (error) {
    console.error('Error in executeExpression:', error)
    return ''
  }
}
