import { getKeyByValue, isNumber } from './helpers'
import getRawMessage from 'i18n/locales/localeDirectAccess'
import isNil from 'ramda/src/isNil'

export const fieldTypes = {
  dropdown: 'dropdown',
  email: 'email',
  money: 'money',
  checkbox: 'checkbox',
  short_text: 'short_text',
  phone: 'phone',
  date: 'date',
  multiple_select: 'multiple_select',
  file: 'file',
  website: 'website',
  choice: 'choice',
  long_text: 'long_text',
  matrix: 'matrix',
  number: 'number',
  time: 'time',
  yes_no: 'yes_no',
  rating: 'rating',
  meta: 'meta',
  regex: 'regex',
  phone_verification: 'phone_verification',
  email_verification: 'email_verification',
  signature: 'signature',
  product: 'product',
  city: 'city',
  country: 'country',
  table: 'table',
  lookup: 'lookup',
  oembed: 'oembed',
  hidden: 'hidden',
  variable: 'variable',
}

export const fieldSubTypes = {
  [fieldTypes.rating]: {
    star: 'star',
    nps: 'nps',
    score: 'score',
    like_dislike: 'like_dislike',
  },
  [fieldTypes.meta]: {
    section: 'section',
    video: 'video',
    page_break: 'page_break',
  },
  [fieldTypes.variable]: {
    int: 'int',
    decimal: 'decimal',
    formula: 'formula',
    string: 'string',
  },
}

export const formTypes = {
  singlePage: 'simple',
  multiPage: 'multi_step',
  nps: 'nps',
}

export const getFieldType = field => {
  const typeKey = getKeyByValue(fieldTypes, field.type)

  let subTypeKey = undefined
  if ('sub_type' in field && field.sub_type) {
    subTypeKey = getKeyByValue(fieldSubTypes[typeKey], field.sub_type)
  }

  return {
    type: typeKey,
    subType: subTypeKey,
  }
}

export const fieldTypeIs = (field, type) =>
  getFieldType(field).type === type || getFieldType(field).subType === type

export const isFieldPageBreak = field =>
  field.type === fieldTypes.meta &&
  field.sub_type === fieldSubTypes[fieldTypes.meta].page_break

export const SingleStepLayout = {
  oneColumn: 'one_column',
  twoColumn: 'two_columns',
}

export const logicActionTypes = {
  jump: 'jump',
  hide: 'hide',
  show: 'show',
  submit: 'submit',
  redirect: 'redirect',
  add: 'add',
  multiply: 'multiply',
  divide: 'divide',
  subtract: 'subtract',
}

export const logicReverseActionTypes = {
  hide: 'show',
  show: 'hide',
  add: 'subtract',
  multiply: 'divide',
  divide: 'multiply',
  subtract: 'add',
}

const getOptionString = option => {
  return option
    ? `<span key=${option.slug}>
          ${option.image ? `<img src=${option.image} alt="" />` : ''}
          <span>${option.title}</span>
        </span>
      `
    : ''
}

export const getStringifyFieldValue = (field, value) => {
  if (!field || isNil(value)) return ''

  switch (field.type) {
    case fieldTypes.choice:
    case fieldTypes.dropdown:
      const x = field.choice_items?.find(choice => choice.slug === value)
      return getOptionString(x)

    case fieldTypes.multiple_select:
      return field.choice_items
        ?.filter(choice => value?.includes(choice.slug))
        .map(getOptionString)

    case fieldTypes.rating:
      if (field.sub_type === fieldSubTypes.rating.like_dislike) {
        const ans = {
          1: getRawMessage('form.rating.nps.options.like'),
          '-1': getRawMessage('form.rating.nps.options.dislike'),
        }
        return ans[`${value}`]
      }

      return value

    case fieldTypes.yes_no:
      const ans = {
        no: getRawMessage('general.no'),
        yes: getRawMessage('general.yes'),
      }
      return ans[value]

    case fieldTypes.city:
    case fieldTypes.country:
    case fieldTypes.matrix:
    case fieldTypes.table:
    case fieldTypes.signature:
    case fieldTypes.file:
      return ''

    default:
      return isNumber(value)
        ? Intl.NumberFormat('en', {
            maximumFractionDigits:
              field.sub_type === 'formula' ? 3 : field.decimal_place,
            useGrouping: false,
          }).format(value)
        : value
  }
}

export const DRAFT_TOKEN_KEY = '__dr' // used in url parameters to display submit_code of drafted form (Partial submit)
