export const actionTypes = {
  LOGIN: 'LOGIN',

  END_LOADING: 'END_LOADING',
  LOADED_FORM: 'LOADED_FORM',
  FORM_RESET: 'FORM_RESET',

  LOADED_ANSWER: 'LOADED_ANSWER',
  FAIL_TO_LOAD_ANSWER: 'FAIL_TO_LOAD_ANSWER',
  RESET_ANSWER: 'RESET_ANSWER',

  HAD_ERROR_LOADING_FORM: 'HAD_ERROR_LOADING_FORM',
  FINISHED_ENHANCING_FORM: 'FINISHED_ENHANCING_FORM',
  LAZY_LOADING_FINISHED: 'LAZY_LOADING_FINISHED',

  FORM_SUCCESSFULLY_SUBMITTED: 'FORM_SUCCESSFULLY_SUBMITTED',

  SET_LOCALE_IN_USE: 'SET_LOCALE_IN_USE',

  SET_FORM_PAGE: 'SET_FORM_PAGE',

  SET_GENERAL_SUBMIT_DATA: 'SET_GENERAL_SUBMIT_DATA',
  SET_SUBMIT_BUTTON_PROPS: 'SET_SUBMIT_BUTTON_PROPS',

  UPDATE_HISTORY: 'UPDATE_FIELDS_HISTORY',
  UPDATE_SINGLE_FIELD_HISTORY: 'UPDATE_SINGLE_FIELD_HISTORY',
  RESET_SINGLE_FIELD_HISTORY: 'RESET_SINGLE_FIELD_HISTORY',
}
