/*
  NOTE: it's important to keep this file compatible with CommonJs module system.
        the content of this file is read before build begins, without any transpile.
*/

//
// ─── ENV CHECKS ─────────────────────────────────────────────────────────────────
//
const BUILD_DOMAIN = process.env.REACT_APP_DOMAIN || `staging.formaloo.com`

const ApiSettings = {
  PORT: process.env.PORT || 3001,
  inDevMode: process.env.NODE_ENV === 'development',
  LOCALE: process.env.REACT_APP_LOCALE || 'fa',
  BUILD_DOMAIN,
  APIKEY:
    process.env.REACT_APP_API_KEY || `f0a5ce1ecc1fea87a57f06a52a8e12c48cb16d34`,
  APIURL: process.env.REACT_APP_API_URL || `https://api.${BUILD_DOMAIN}`,
}

const Environment = {
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
}

//
// ─── LOCALE SETTINGS ─────────────────────────────────────────────────────────────
//

const locales = {
  fa: 'fa',
  en: 'en',
}

// locale settings
const localeSettings = {
  [locales.fa]: {
    dir: 'rtl',
  },
  [locales.en]: {
    dir: 'ltr',
  },
}

const LOCALE_IN_USE = ApiSettings.LOCALE

export function isRTL() {
  if (LOCALE_IN_USE in localeSettings) {
    return localeSettings[LOCALE_IN_USE].dir === 'rtl'
  }

  throw new Error(
    'locale in use is not included in localeSettings, please check the settings.js',
  )
}

const LocalSettings = {
  locales,
  localeSettings,
  LOCALE_IN_USE,
  isRTL,
}

//
// ─── DOM ELEMENTS SETTINGS ──────────────────────────────────────────────────────
//

const DomElementSettings = {
  wrapperElementId: 'formz-wrapper',
  wrapperElementDataAttribute: 'formz-slug',
  wrapperElementTypeAttribute: 'formz-type',
}
//
// ─── DOMAIN SETTINGS ────────────────────────────────────────────────────────────
//

// domain names definition
const localDevDomain = 'localhost'
const fa_domainName = 'formaloo.com'
const en_domainName = 'formaloo.net'

const DomainSettings = {
  widgetBaseUrl:
    process.env.REACT_APP_WIDGET_BASE_URL ||
    'https://staging.chatbot.formaloo.com/preview',
  formzOwnDomains: [
    localDevDomain,
    fa_domainName,
    en_domainName,
    'www.formaloo.net',
    'formaloo.net',
    'formaloo.com',
    'www.formaloo.com',
    'formz.ir',
    'www.formz.ir',
    'staging.formaloo.com',
  ],
  baseUrl: ApiSettings.inDevMode
    ? `https://localhost:${ApiSettings.PORT}`
    : `https://${ApiSettings.BUILD_DOMAIN}`,
  domainName: ApiSettings.inDevMode ? 'localhost' : ApiSettings.BUILD_DOMAIN,
  websiteDomain: process.env.REACT_APP_WEBSITE_DOMAIN || 'https://formaloo.com',
}
//
// ─── ASSETS SETTINGS ────────────────────────────────────────────────────────────
//

// CSS

const CssSettings = {
  formzCssLinkId: 'formz-css',
  formCssUrl: `${DomainSettings.baseUrl}/istatic/css/main.css`,
  hostedCssUrlPattern: `^${DomainSettings.baseUrl
    .split('.')
    // eslint-disable-next-line no-useless-escape
    .join('.')}/static/css/main\.(.+)\.css$`,
}

//
// ─── LOCAL-SESSION STORAGE ──────────────────────────────────────────────────────
//

const LocalStorageSettings = {
  sessionStorageKey: 'formz-client-auth-data',
  sessionStorageInitialDataKey: 'formz-submit-initial-data',
  sessionStorageIFrameEvent: 'F-COF-EVENT', // shorthand of formz-cross-origin-frame-event :)
}

const externalCDNs = {
  googleFontCDN: 'https://fonts.googleapis.com',
}

export const BrandingSettings = {
  customLogoUrl: process.env.REACT_APP_CUSTOM_LOGO_URL,
  customFaviconUrl: process.env.REACT_APP_CUSTOM_FAVICON_URL,
}

const settings = {
  ...ApiSettings,
  ...Environment,
  ...LocalSettings,
  ...DomElementSettings,
  ...DomainSettings,
  ...CssSettings,
  ...LocalStorageSettings,
  ...externalCDNs,
  BrandingSettings,
}

export default settings
