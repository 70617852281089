import * as dayjs from 'dayjs'
import {
  convertType,
  findExitingHistoryLog,
  setHistoryLogValue,
  convertMathOperators,
  formatValue,
  executeExpression,
} from './calculationHelpers'

export const isFieldEmpty = value => {
  const objField = typeof value === 'object'
  return (
    !value ||
    (Array.isArray(value) && value.length < 1) ||
    (objField && !Object.keys(value).length) ||
    (value instanceof FileList && !value.length)
  )
}

export const checkEqual = ({ desiredAnswer, fields, getFieldValue }) => {
  // if there is no constant or choice to compare with fields, then compare fields together
  if (!desiredAnswer) {
    const firstFieldValue = getFieldValue(fields[0].value)
    return fields.every(field => getFieldValue(field.value) === firstFieldValue)
  }

  return fields.every(field => {
    const fieldValue = getFieldValue(field.value)
    // when the field is multi_select or the field's value is array
    if (fieldValue && Array.isArray(fieldValue)) {
      return fieldValue.includes(desiredAnswer.value)
    }
    return fieldValue === desiredAnswer.value
  })
}

export const isAnswered = ({ fields = [], getFieldValue }) => {
  return fields.every(field => !isFieldEmpty(getFieldValue(field.value)))
}

export const checkAlways = () => {
  return true
}

export const greaterThan = ({ desiredAnswer, fields, getFieldValue }) => {
  // if there is no constant or choice to compare with fields, then compare two first fields together
  if (!desiredAnswer) {
    return Boolean(
      getFieldValue(fields[0].value) > getFieldValue(fields[1].value),
    )
  }
  return fields.every(field => getFieldValue(field.value) > desiredAnswer.value)
}

export const greaterThanEqual = ({ desiredAnswer, fields, getFieldValue }) => {
  // if there is no constant or choice to compare with fields, then compare two first fields together
  if (!desiredAnswer) {
    return Boolean(
      getFieldValue(fields[0].value) >= getFieldValue(fields[1].value),
    )
  }
  return fields.every(
    field => getFieldValue(field.value) >= desiredAnswer.value,
  )
}

export const lowerThan = ({ desiredAnswer, fields, getFieldValue }) => {
  // if there is no constant or choice to compare with fields, then compare two first fields together
  if (!desiredAnswer) {
    return Boolean(
      getFieldValue(fields[0].value) < getFieldValue(fields[1].value),
    )
  }
  return fields.every(field => getFieldValue(field.value) < desiredAnswer.value)
}
export const lowerThanEqual = ({ desiredAnswer, fields, getFieldValue }) => {
  // if there is no constant or choice to compare with fields, then compare two first fields together
  if (!desiredAnswer) {
    return Boolean(
      getFieldValue(fields[0].value) <= getFieldValue(fields[1].value),
    )
  }
  return fields.every(
    field => getFieldValue(field.value) <= desiredAnswer.value,
  )
}
export const contains = ({ desiredAnswer, fields, getFieldValue }) => {
  // only compare field with constant
  if (desiredAnswer && desiredAnswer.type === 'constant') {
    const str = getFieldValue(fields[0].value) || ''
    return str.match(new RegExp(desiredAnswer.value, 'gi'))
  }
  return false
}
export const startsWith = ({ desiredAnswer, fields, getFieldValue }) => {
  // only compare field with constant
  if (desiredAnswer && desiredAnswer.type === 'constant') {
    const str = getFieldValue(fields[0].value) || ''
    // startsWith is case sensitive. Convert the value to lower case then check the condition
    return str.toLowerCase().startsWith(desiredAnswer.value)
  }
  return false
}

export const endsWith = ({ desiredAnswer, fields, getFieldValue }) => {
  // only compare field with constant
  if (desiredAnswer && desiredAnswer.type === 'constant') {
    const str = getFieldValue(fields[0].value) || ''
    // endsWidth is case sensitive. Convert the value to lower case then check the condition
    return str.toLowerCase().endsWith(desiredAnswer.value)
  }
  return false
}

export const onDate = ({ desiredAnswer, fields, getFieldValue }) => {
  const date1 = dayjs(getFieldValue(fields[0].value))
  if (date1.isValid()) {
    if (!desiredAnswer && fields[1]) {
      const date2 = getFieldValue(fields[1].value)
      return dayjs(date2).isSame(date1)
    }
    if (desiredAnswer) {
      return dayjs(desiredAnswer.value).isSame(date1)
    }
  }
  return false
}

export const beforeDate = ({ desiredAnswer, fields, getFieldValue }) => {
  const date1 = dayjs(getFieldValue(fields[0].value))
  if (getFieldValue(fields[0].value) && date1.isValid()) {
    if (!desiredAnswer && fields[1]) {
      const date2 = getFieldValue(fields[1].value)
      return dayjs(date1).isBefore(date2)
    }
    if (desiredAnswer) {
      return dayjs(date1).isBefore(desiredAnswer.value)
    }
  }
  return false
}

export const afterDate = ({ desiredAnswer, fields, getFieldValue }) => {
  const date1 = dayjs(getFieldValue(fields[0].value))
  if (getFieldValue(fields[0].value) && date1.isValid()) {
    if (!desiredAnswer && fields[1]) {
      const date2 = getFieldValue(fields[1].value)
      return dayjs(date1).isAfter(date2)
    }
    if (desiredAnswer) {
      return dayjs(date1).isAfter(desiredAnswer.value)
    }
  }
  return false
}

export const beforeOrOnDate = conditionProps =>
  beforeDate(conditionProps) || onDate(conditionProps)

export const afterOrOnDate = conditionProps =>
  afterDate(conditionProps) || onDate(conditionProps)

const getFieldFormValue = (field, variableAlias, getValue) => {
  const fieldValue = getValue(field?.slug || '')
  const isDefaultVariablePrice = Boolean(variableAlias === 'price')

  switch (field?.type) {
    case 'product':
      return isDefaultVariablePrice
        ? fieldValue
          ? Number(field?.unit_price) * Number(fieldValue) // apply total price of product to price variable
          : 0
        : fieldValue // apply the total amount of product field

    default:
      return fieldValue
  }
}

export const calculateValue = ({
  rule,
  setFieldValue,
  getFieldValue: getFieldRawValue,
  getFieldFullData: getFormFieldData,
  setHistory,
  history,
  fieldSlug,
  isReversing,
}) => {
  const getFieldFullData = fieldSlug => {
    if (fieldSlug === '__SUBMIT__') return { slug: '__SUBMIT__' }
    return getFormFieldData(fieldSlug)
  }

  if (rule.args) {
    // variable data
    const variable = rule.args.find(arg => arg.type === 'variable')
    const variableSlug = variable?.identifier

    //Operand data
    const secondOperand = rule.args.find(arg =>
      ['field', 'constant'].includes(arg.type),
    )

    if (variable && secondOperand && variableSlug) {
      // variable data
      const variableType = getFieldFullData(variableSlug)?.sub_type
      const variableDefault = convertType(
        getFieldFullData(variable.identifier)?.default,
        variableType,
      )

      const variableHistory = history && history?.[variableSlug]

      const isFieldOperand = Boolean(secondOperand.type === 'field')
      const secondOperandValue = isFieldOperand
        ? getFieldFormValue(
            getFieldFullData(secondOperand?.identifier),
            getFieldFullData(variableSlug)?.alias || '',
            getFieldRawValue,
          )
        : secondOperand.value // constant value

      // TODO: check this condition
      if (variableHistory) {
        const currentLogicChangesIndex = findExitingHistoryLog(
          variableHistory,
          {
            field: getFieldFullData(fieldSlug),
            secondOperand,
            action: rule.action,
            operandType: secondOperand.type,
            whenArgs: rule.when?.args,
          },
        )

        if (isReversing) {
          if (currentLogicChangesIndex >= 0) {
            variableHistory.splice(currentLogicChangesIndex, 1)
          } else {
            return
          }
        } else {
          const newChangeLog = {
            logicField: getFieldFullData(fieldSlug), // the field which logic is set on it
            operandIdentifier: secondOperand.identifier,
            valueType: variableType,
            value: setHistoryLogValue(secondOperandValue, rule.action),
            action: rule.action,
            whenArgs: rule.when?.args,
          }
          currentLogicChangesIndex >= 0
            ? variableHistory.splice(currentLogicChangesIndex, 1, newChangeLog)
            : variableHistory.push(newChangeLog)
        }

        const formula = variableHistory.reduce((prev, curr) => {
          /* put every new value and its action in parentheses to */
          return `(${prev})${convertMathOperators(curr.action)}${formatValue(
            curr.value,
            curr.valueType,
          )}`
        }, variableDefault || 0)

        /* update variable history */
        setHistory(variableSlug, variableHistory)
        /* update variable value in form */
        setFieldValue(variableSlug, executeExpression(formula))
      }
    }
  }
}
