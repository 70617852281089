import settings from '../../settings'

let localeDictionary = {}
const { LOCALE_IN_USE } = settings

if (LOCALE_IN_USE === 'en') {
  localeDictionary = require('../locales/en.json')
} else {
  localeDictionary = require('../locales/fa.json')
}

export default function getRawMessage(id, values = {}) {
  if (id in localeDictionary) {
    let message = localeDictionary[id]
    Object.keys(values).forEach(
      (k) => (message = message.replace(`{${k}}`, values[k]))
    )

    return message
  }

  return id
}
