import { httpClient } from './utils/http'
import API from 'settings/api.settings'
import { config, createFormData } from './utils'

export const storageService = () => ({
  upload: (formData, params = {}) => {
    return httpClient.post(
      `${API.baseUrl}/v2.0/files/`,
      formData,
      config(params),
    )
  },
  get: (fileSlug, params = {}) =>
    httpClient.get(
      `${API.baseUrl}/v2.0/files/${fileSlug}/`,
      config({ params }),
    ),
})
