import { httpClient } from './utils/http'
import API from 'settings/api.settings'
import { config, createFormData } from './utils'

export const verificationService = () => ({
  sendEmail: (formData, params = {}) => {
    return httpClient.post(
      `${API.baseUrl}/v3.0/email_verifications/`,
      formData.formData,
      config(params),
    )
  },
  verifyEmail: (uuid, formData, params = {}) => {
    return httpClient.put(
      `${API.baseUrl}/v3.0/email_verifications/${uuid}/verify/`,
      formData,
      config(params),
    )
  },
})
